<template>
  <div class="flex w-full h-full">
    <div class="flex items-center justify-center w-full h-full text-white">
      <span v-if="authed">Logging out</span>
      <div class="flex flex-col items-center" v-else>
        <span class="text-gray-400"
          ><fa-icon icon="check" class="mr-2 fa-fw" />Logged out</span>
        <a
          href="/login"
          class="mt-4 text-sm text-gray-200 underline hover:cursor-pointer hover:text-gray-100"
          >Back to login</a
        >
      </div>

    </div>
  </div>
</template>
<script>
export default {
  computed: {
    authed () {
      return this.$store.getters['auth/isAuthed']
    }
  }
}
</script>

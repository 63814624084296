<template>
  <div
    class="relative flex flex-col justify-center min-h-screen py-6 overflow-hidden bg-gray-50 sm:py-12"
  >
    <img
      src="@assets/img/backgrounds/beams.jpeg"
      alt=""
      class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 max-w-none"
      width="1308"
    />
    <div
      class="relative px-6 pt-10 pb-8 bg-white shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10"
    >
      <div class="max-w-md mx-auto">
        <img
          src="@assets/img/logos/disguise_logo_RGB_Black.svg"
          alt="Disguise Logo"
          class="w-32 md:w-40"
        />
        <div class="divide-y divide-gray-300/50">
          <div class="pt-8 pb-4 space-y-6 text-base leading-7 text-gray-600">
            <p>You must be logged in to continue.</p>
          </div>
          <div class="pt-4 text-base font-semibold leading-7">
            <p>
              <a
                :href="baseGatewayUrl"
                class="text-indigo-500 hover:text-indigo-600"
                >Login via Disguise Cloud
                <fa-icon
                  icon="arrow-up-right-from-square"
                  class="text-sm fa-fw"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.authCheck()
  },

  methods: {
    authCheck() {
      // let authedAndReady = this.$store.getters['auth/isAuthedAndReady']
      // if (authedAndReady) {
      //   this.$router.push('/dashboard')
      // }
    }
  },

  computed: {
    baseGatewayUrl() {
      return process.env.VUE_APP_DISGUISE_GATEWAY_BASEURL + '/login-session'
    }
  }
}
</script>
